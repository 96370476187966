import React, { useState } from 'react';
import { X, ChevronLeft, ChevronRight, Grid, Layout } from 'lucide-react';

// Import your conference images
import M1_1 from './ConferenceImages/M1_ (1).JPG';
import M1_2 from './ConferenceImages/M1_ (2).JPG';
import M1_3 from './ConferenceImages/M1_ (3).JPG';
import M1_4 from './ConferenceImages/M1_ (4).JPG';
import M1_5 from './ConferenceImages/M1_ (5).JPG';
import M1_6 from './ConferenceImages/M1_ (6).JPG';
import M1_7 from './ConferenceImages/M1_ (7).JPG';
import M1_8 from './ConferenceImages/M1_ (8).JPG';
import M1_9 from './ConferenceImages/M1_ (9).JPG';
import M1_10 from './ConferenceImages/M1_ (10).JPG';
import M1_11 from './ConferenceImages/M1_ (11).JPG';
import M1_12 from './ConferenceImages/M1_ (12).JPG';
import M1_13 from './ConferenceImages/M1_ (13).JPG';
import M1_14 from './ConferenceImages/M1_ (14).JPG';
import M1_15 from './ConferenceImages/M1_ (15).JPG';
import M1_16 from './ConferenceImages/M1_ (16).JPG';
import M1_17 from './ConferenceImages/M1_ (17).JPG';
import M1_18 from './ConferenceImages/M1_ (18).JPG';
import M1_19 from './ConferenceImages/M1_ (19).JPG';
import M1_20 from './ConferenceImages/M1_ (20).JPG';
import M1_21 from './ConferenceImages/M1_ (21).JPG';
import M1_22 from './ConferenceImages/M1_ (22).JPG';
import M1_23 from './ConferenceImages/M1_ (23).JPG';
import M1_24 from './ConferenceImages/M1_ (24).JPG';
import M1_25 from './ConferenceImages/M1_ (25).JPG';
import M1_26 from './ConferenceImages/M1_ (26).JPG';
import M1_27 from './ConferenceImages/M1_ (27).JPG';
import M1_28 from './ConferenceImages/M1_ (28).JPG';
import M1_29 from './ConferenceImages/M1_ (29).JPG';
import M1_30 from './ConferenceImages/M1_ (30).JPG';
import M1_31 from './ConferenceImages/M1_ (31).JPG';
import M1_32 from './ConferenceImages/M1_ (32).JPG';
import M1_33 from './ConferenceImages/M1_ (33).JPG';
import M1_34 from './ConferenceImages/M1_ (34).JPG';
import M1_35 from './ConferenceImages/M1_ (35).JPG';
import M1_36 from './ConferenceImages/M1_ (36).JPG';
import M1_37 from './ConferenceImages/M1_ (37).JPG';
import M1_38 from './ConferenceImages/M1_ (38).JPG';
import M1_39 from './ConferenceImages/M1_ (39).JPG';
import M1_40 from './ConferenceImages/M1_ (40).JPG';
import M1_41 from './ConferenceImages/M1_ (41).JPG';
import M1_42 from './ConferenceImages/M1_ (42).JPG';
import M1_43 from './ConferenceImages/M1_ (43).JPG';
import M1_44 from './ConferenceImages/M1_ (44).JPG';
import M1_45 from './ConferenceImages/M1_ (45).JPG';
import M1_46 from './ConferenceImages/M1_ (46).JPG';
import M1_47 from './ConferenceImages/M1_ (47).JPG';
import M1_48 from './ConferenceImages/M1_ (48).JPG';
import M1_49 from './ConferenceImages/M1_ (49).JPG';
import M2 from './ConferenceImages/M2.jpeg';

const galleryImages = [
  { id: 'm1', thumbnail: M1_1, full: M1_1, title: "Conference Moment 1", description: "Keynote Session", category: "Conference" },
    { id: 'm2', thumbnail: M1_2, full: M1_2, title: "Conference Moment 2", description: "Panel Discussion", category: "Conference" },
    { id: 'm3', thumbnail: M1_3, full: M1_3, title: "Conference Moment 3", description: "Award Ceremony", category: "Conference" },
    { id: 'm4', thumbnail: M1_4, full: M1_4, title: "Conference Moment 4", description: "Networking Break", category: "Conference" },
    { id: 'm5', thumbnail: M1_5, full: M1_5, title: "Conference Moment 5", description: "Technical Presentation", category: "Conference" },
    { id: 'm6', thumbnail: M1_6, full: M1_6, title: "Conference Moment 6", description: "Q&A Session", category: "Conference" },
    { id: 'm7', thumbnail: M1_7, full: M1_7, title: "Conference Moment 7", description: "Workshop Highlights", category: "Conference" },
    { id: 'm8', thumbnail: M1_8, full: M1_8, title: "Conference Moment 8", description: "Group Discussion", category: "Conference" },
    { id: 'm9', thumbnail: M1_9, full: M1_9, title: "Conference Moment 9", description: "Poster Presentation", category: "Conference" },
    { id: 'm10', thumbnail: M1_10, full: M1_10, title: "Conference Moment 10", description: "Keynote Address", category: "Conference" },
    { id: 'm11', thumbnail: M1_11, full: M1_11, title: "Conference Moment 11", description: "Panel Debate", category: "Conference" },
    { id: 'm12', thumbnail: M1_12, full: M1_12, title: "Conference Moment 12", description: "Breakout Session", category: "Conference" },
    { id: 'm13', thumbnail: M1_13, full: M1_13, title: "Conference Moment 13", description: "Lunch Break", category: "Conference" },
    { id: 'm14', thumbnail: M1_14, full: M1_14, title: "Conference Moment 14", description: "Workshop Opening", category: "Conference" },
    { id: 'm15', thumbnail: M1_15, full: M1_15, title: "Conference Moment 15", description: "Workshop Hands-on", category: "Conference" },
    { id: 'm16', thumbnail: M1_16, full: M1_16, title: "Conference Moment 16", description: "Keynote Speech", category: "Conference" },
    { id: 'm17', thumbnail: M1_17, full: M1_17, title: "Conference Moment 17", description: "Networking Event", category: "Conference" },
    { id: 'm18', thumbnail: M1_18, full: M1_18, title: "Conference Moment 18", description: "Panel Insights", category: "Conference" },
    { id: 'm19', thumbnail: M1_19, full: M1_19, title: "Conference Moment 19", description: "Industry Talk", category: "Conference" },
    { id: 'm20', thumbnail: M1_20, full: M1_20, title: "Conference Moment 20", description: "Group Discussion", category: "Conference" },
    { id: 'm21', thumbnail: M1_21, full: M1_21, title: "Conference Moment 21", description: "Tech Demo", category: "Conference" },
    { id: 'm22', thumbnail: M1_22, full: M1_22, title: "Conference Moment 22", description: "Project Showcase", category: "Conference" },
    { id: 'm23', thumbnail: M1_23, full: M1_23, title: "Conference Moment 23", description: "Expert Panel", category: "Conference" },
    { id: 'm24', thumbnail: M1_24, full: M1_24, title: "Conference Moment 24", description: "Closing Remarks", category: "Conference" },
    { id: 'm25', thumbnail: M1_25, full: M1_25, title: "Conference Moment 25", description: "Networking Lunch", category: "Conference" },
    { id: 'm26', thumbnail: M1_26, full: M1_26, title: "Conference Moment 26", description: "Research Presentation", category: "Conference" },
    { id: 'm27', thumbnail: M1_27, full: M1_27, title: "Conference Moment 27", description: "Interactive Session", category: "Conference" },
    { id: 'm28', thumbnail: M1_28, full: M1_28, title: "Conference Moment 28", description: "Workshop Wrap-up", category: "Conference" },
    { id: 'm29', thumbnail: M1_29, full: M1_29, title: "Conference Moment 29", description: "Feedback Session", category: "Conference" },
    { id: 'm30', thumbnail: M1_30, full: M1_30, title: "Conference Moment 30", description: "Awards Distribution", category: "Conference" },
    { id: 'm31', thumbnail: M1_31, full: M1_31, title: "Conference Moment 31", description: "Technical Session", category: "Conference" },
    { id: 'm32', thumbnail: M1_32, full: M1_32, title: "Conference Moment 32", description: "Workshop Highlights", category: "Conference" },
    { id: 'm33', thumbnail: M1_33, full: M1_33, title: "Conference Moment 33", description: "Innovator's Panel", category: "Conference" },
    { id: 'm34', thumbnail: M1_34, full: M1_34, title: "Conference Moment 34", description: "Tech Keynote", category: "Conference" },
    { id: 'm35', thumbnail: M1_35, full: M1_35, title: "Conference Moment 35", description: "Networking Session", category: "Conference" },
    { id: 'm36', thumbnail: M1_36, full: M1_36, title: "Conference Moment 36", description: "Demo Showcase", category: "Conference" },
    { id: 'm37', thumbnail: M1_37, full: M1_37, title: "Conference Moment 37", description: "Workshop Networking", category: "Conference" },
    { id: 'm38', thumbnail: M1_38, full: M1_38, title: "Conference Moment 38", description: "Expert Debate", category: "Conference" },
    { id: 'm39', thumbnail: M1_39, full: M1_39, title: "Conference Moment 39", description: "Tech Quiz", category: "Conference" },
    { id: 'm40', thumbnail: M1_40, full: M1_40, title: "Conference Moment 40", description: "Breakout Session", category: "Conference" },
    { id: 'm41', thumbnail: M1_41, full: M1_41, title: "Conference Moment 41", description: "Panel Presentation", category: "Conference" },
    { id: 'm42', thumbnail: M1_42, full: M1_42, title: "Conference Moment 42", description: "Innovation Showcase", category: "Conference" },
    { id: 'm43', thumbnail: M1_43, full: M1_43, title: "Conference Moment 43", description: "Startup Pitches", category: "Conference" },
    { id: 'm44', thumbnail: M1_44, full: M1_44, title: "Conference Moment 44", description: "Award Announcement", category: "Conference" },
    { id: 'm45', thumbnail: M1_45, full: M1_45, title: "Conference Moment 45", description: "Panel Q&A", category: "Conference" },
    { id: 'm46', thumbnail: M1_46, full: M1_46, title: "Conference Moment 46", description: "Networking Dinner", category: "Conference" },
    { id: 'm47', thumbnail: M1_47, full: M1_47, title: "Conference Moment 47", description: "Conference Wrap-Up", category: "Conference" },
    { id: 'm48', thumbnail: M1_48, full: M1_48, title: "Conference Moment 48", description: "Closing Ceremony", category: "Conference" },
    { id: 'm49', thumbnail: M1_49, full: M1_49, title: "Conference Moment 49", description: "Farewell Speech", category: "Conference" },
    { id: 'm50', thumbnail: M2, full: M2, title: "Conference Moment 50", description: "Final Thoughts", category: "Conference" }
];

const ConferenceGallery = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [viewMode, setViewMode] = useState('grid');

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
    setViewMode('slideshow');
  };

  const handleNextImage = () => {
    const currentIndex = galleryImages.findIndex(img => img.id === selectedImage.id);
    const nextIndex = (currentIndex + 1) % galleryImages.length;
    setSelectedImage(galleryImages[nextIndex]);
  };

  const handlePrevImage = () => {
    const currentIndex = galleryImages.findIndex(img => img.id === selectedImage.id);
    const prevIndex = (currentIndex - 1 + galleryImages.length) % galleryImages.length;
    setSelectedImage(galleryImages[prevIndex]);
  };

  return (
    <div className="min-h-screen bg-gray-50 pt-20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-12">
          <h1 className="text-4xl font-bold text-gray-900 mb-4">Conference Gallery</h1>
          <p className="text-lg text-gray-600">Capturing moments from AIC-RAETHM 2025</p>
        </div>

        {/* Gallery Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
          {galleryImages.map((image) => (
            <div
              key={image.id}
              onClick={() => handleImageClick(image)}
              className="group cursor-pointer relative overflow-hidden rounded-lg shadow-md"
            >
              <img
                src={image.thumbnail}
                alt={image.title}
                className="w-full h-64 object-cover transition-transform group-hover:scale-105"
              />
              <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-40 transition-opacity">
                <div className="absolute bottom-0 left-0 right-0 p-4 text-white transform translate-y-full group-hover:translate-y-0 transition-transform">
                  <h3 className="font-semibold">{image.title}</h3>
                  <p className="text-sm opacity-90">{image.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Modal/Lightbox */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-90 z-50 flex items-center justify-center">
          <div className="relative w-full max-w-6xl mx-auto p-4">
            {/* Close and View Mode Buttons */}
            <div className="absolute top-4 right-4 flex items-center gap-4 z-10">
              <button
                onClick={() => setViewMode(viewMode === 'grid' ? 'slideshow' : 'grid')}
                className="p-2 rounded-full bg-gray-800 text-white hover:bg-gray-700"
              >
                {viewMode === 'grid' ? <Layout size={20} /> : <Grid size={20} />}
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="p-2 rounded-full bg-gray-800 text-white hover:bg-gray-700"
              >
                <X size={20} />
              </button>
            </div>

            {/* Image Display */}
            <div className="relative">
              <img
                src={selectedImage.full}
                alt={selectedImage.title}
                className="max-h-[80vh] mx-auto rounded-lg"
              />
              
              {/* Navigation Buttons */}
              <button
                onClick={handlePrevImage}
                className="absolute left-4 top-1/2 transform -translate-y-1/2 p-2 rounded-full bg-gray-800 text-white hover:bg-gray-700"
              >
                <ChevronLeft size={24} />
              </button>
              <button
                onClick={handleNextImage}
                className="absolute right-4 top-1/2 transform -translate-y-1/2 p-2 rounded-full bg-gray-800 text-white hover:bg-gray-700"
              >
                <ChevronRight size={24} />
              </button>

              {/* Image Info */}
              <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black to-transparent text-white">
                <h3 className="text-xl font-bold">{selectedImage.title}</h3>
                <p className="text-sm opacity-90">{selectedImage.description}</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConferenceGallery;