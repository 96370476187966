import React from 'react';
import { Link } from 'react-router-dom';
import aboutBg from './image/AB5.jpg';
import { Briefcase, Users, Globe, Lightbulb } from 'lucide-react';

const About = () => {
  return (
    <div className='relative w-full min-h-screen bg-cover bg-center' style={{ backgroundImage: `url(${aboutBg})` }}>
      <div className='absolute inset-0 bg-black bg-opacity-60'></div>
      <div className='relative z-10 flex flex-col items-center text-center text-white p-6 md:p-12'>
        <h1 className='text-4xl font-bold mb-6'>About Us</h1>

        <div className='flex flex-col md:flex-row items-center justify-between max-w-5xl w-full bg-white bg-opacity-10 p-6 rounded-lg shadow-lg'>
          {/* Left Section */}
          <div className='md:w-1/2 text-left text-lg text-gray-200 space-y-4 p-4'>
            {/* Truncated Description for Small Screens */}
            <p className='block md:hidden'>
              At AEG Consultancy Services PVT. LTD., we believe that research is the cornerstone of innovation and progress. Our mission is to empower researchers...
            </p>
            {/* Full Description for Medium and Larger Screens */}
            <p className='hidden md:block'>
              At AEG Consultancy Services PVT. LTD., we believe that research is the cornerstone of innovation and progress. Our mission is to empower researchers by offering an all-in-one platform that integrates consultancy, equipment supply, proposal writing, and international collaboration opportunities.
            </p>
            <p className='hidden md:block'>
              In a world where research drives change, we understand the challenges that researchers face in accessing the right resources, tools, and support. That’s why we are committed to providing solutions that simplify the research process. Thank you for trusting us to be a part of your research journey.
            </p>
            
            <p className='font-semibold'>AEG Consultancy Services PVT. LTD.</p>
            <Link to='/ContactForm'>
              {/* <button className='mt-4 px-6 py-2 bg-orange-500 hover:bg-orange-600 text-black font-bold rounded-full shadow-lg transition duration-300'>Contact Us</button> */}
            </Link>
          </div>

          {/* Right Section with Tailwind Cards */}
          <div className='md:w-1/2 grid grid-cols-1 md:grid-cols-2 gap-4 p-4'>
            <div className='bg-gray-100 bg-opacity-30 text-white p-4 rounded-lg shadow-lg flex flex-col items-center'>
              <Briefcase size={40} className='text-orange-400' />
              <div className='text-center mt-2'>
                <h2 className='font-bold text-lg'>Consultancy</h2>
                <p>Expert guidance for your research & innovation.</p>
              </div>
            </div>
            <div className='bg-gray-100 bg-opacity-30 text-white p-4 rounded-lg shadow-lg flex flex-col items-center'>
              <Users size={40} className='text-blue-400' />
              <div className='text-center mt-2'>
                <h2 className='font-bold text-lg'>Collaboration</h2>
                <p>Global networking opportunities for researchers.</p>
              </div>
            </div>
            <div className='bg-gray-100 bg-opacity-30 text-white p-4 rounded-lg shadow-lg flex flex-col items-center'>
              <Globe size={40} className='text-green-400' />
              <div className='text-center mt-2'>
                <h2 className='font-bold text-lg'>International Reach</h2>
                <p>Expand your research beyond borders.</p>
              </div>
            </div>
            <div className='bg-gray-100 bg-opacity-30 text-white p-4 rounded-lg shadow-lg flex flex-col items-center'>
              <Lightbulb size={40} className='text-yellow-400' />
              <div className='text-center mt-2'>
                <h2 className='font-bold text-lg'>Our Vision</h2>
                <p>Empowering researchers through innovation.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;