import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './ConferenceDropdown.css'; // Ensure you have this CSS file for styling

const ConferenceDropdown = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(true); // Start with dropdown open
  const navigate = useNavigate(); // Get the navigate function

  const closeDropdown = () => {
    setIsDropdownOpen(false);
    navigate('/'); // Navigate to the home page
  };

  return (
    <div className={`conference-dropdown ${isDropdownOpen ? 'open' : 'closed'}`}>
      <header className="conference-header">
        <h1>Conference</h1>
        <button className="close-btn" onClick={closeDropdown}>✖</button>
      </header>

      {isDropdownOpen && (
        <div className="conference-content">
          <div className="menu-section">
            <h2>Menu</h2>
            <ul>
              <li><Link to="/Conference">About Conferences</Link></li>
              <li><Link to="/PlanScientificPage">Plan a Scientific Conference</Link></li>
              <li><Link to="/Sponsor">Sponsors & Exhibitors</Link></li>
              <li><Link to="/ConferenceAward">Awards & Recognition</Link></li>
              <li><Link to="/NewWorkshop">Workshops & Courses</Link></li>
              <li><Link to="/faq">Conference FAQ</Link></li>
            </ul>
          </div>

          <div className="cards-section">
            <h2>Quick Links</h2>
            <Link to="/UpcomingConference" className="menu-card">
              <i className="fas fa-calendar-alt"></i>
              <span>Upcoming Conferences</span>
            </Link>
            <Link to="/Webinar" className="menu-card">
              <i className="fas fa-video"></i>
              <span>Webinars</span>
            </Link>
            <Link to="/register" className="menu-card">
              <i className="fas fa-calendar-check"></i>
              <span>Past Conferences</span>
            </Link>
            <Link to="/ConferenceGallery" className="menu-card">
              <i className="fas fa-photo-video"></i>
              <span>Conference Gallery</span>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConferenceDropdown;