import React, { useState } from 'react';

const SubmitProposelForm= () => {
  const [formData, setFormData] = useState({
    universityName: '',
    country: '',
    state: '',
    contactPerson: '',
    department: '',
    designation: '',
    email: '',
    contactNumber: '',
    conferenceName: '',
    conferenceDate: '',
    expectedParticipants: '',
    internationalSpeakers: '',
    nationalSpeakers: '',
    internationalUniversities: '',
    nationalUniversities: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const response = await fetch('http://localhost:5000/submit-proposal', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        alert("Proposal submitted successfully!");
        console.log('Success:', result);
        // Reset the form after submission
        setFormData({
          universityName: '',
          country: '',
          state: '',
          contactPerson: '',
          department: '',
          designation: '',
          email: '',
          contactNumber: '',
          conferenceName: '',
          conferenceDate: '',
          expectedParticipants: '',
          internationalSpeakers: '',
          nationalSpeakers: '',
          internationalUniversities: '',
          nationalUniversities: '',
          message: ''
        });
      } else {
        alert("Failed to submit the proposal. Try again.");
        console.error('Error:', result);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an issue submitting your proposal.");
    }
  };
  

  return (
    <div className="max-w-4xl mx-auto p-4">
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* University/Institution Details */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Submit your Proposal for Organizing
          an  International Conference </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">
                Institution Name *
                <input
                  type="text"
                  name="universityName"
                  value={formData.universityName}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  placeholder="Enter institution name"
                />
              </label>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">
                Country *
                <select
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                >
                  <option value="">Select Country</option>
                  <option value="US">United States</option>
                  <option value="UK">United Kingdom</option>
                  <option value="CA">Canada</option>
                </select>
              </label>
            </div>
          </div>
        </div>

        {/* Conference Details */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Conference Details</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">
                Conference Name *
                <input
                  type="text"
                  name="conferenceName"
                  value={formData.conferenceName}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  placeholder="Enter conference name"
                />
              </label>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">
                Date *
                <input
                  type="date"
                  name="conferenceDate"
                  value={formData.conferenceDate}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                />
              </label>
            </div>
          </div>
        </div>

        {/* Speaker Requirements */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Speaker Requirements</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium mb-1">
                International Speakers  (Optional)
                <input
                  type="number"
                  name="internationalSpeakers"
                  value={formData.internationalSpeakers}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  placeholder="Number of international speakers"
                />
              </label>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">
                National Speakers  (Optional)
                <input
                  type="number"
                  name="nationalSpeakers"
                  value={formData.nationalSpeakers}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  placeholder="Number of national speakers"
                />
              </label>
            </div>
          </div>
        </div>

        {/* Academic Partners */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Academic Partners</h2>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium mb-1">
                International Universities  (Optional)
                <input
                  type="text"
                  name="internationalUniversities"
                  value={formData.internationalUniversities}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  placeholder="Enter international university partners"
                />
              </label>
            </div>
            <div>
              <label className="block text-sm font-medium mb-1">
                National Universities (Optional)
                <input
                  type="text"
                  name="nationalUniversities"
                  value={formData.nationalUniversities}
                  onChange={handleChange}
                  required
                  className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2"
                  placeholder="Enter national university partners"
                />
              </label>
            </div>
          </div>
        </div>

        {/* Message */}
        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          <h2 className="text-xl font-bold mb-4">Additional Information</h2>
          <div>
            <label className="block text-sm font-medium mb-1">
              Leave a Message
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 min-h-[100px]"
                placeholder="Enter your message here"
              />
            </label>
          </div>
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
        >
          Submit Registration
        </button>
      </form>
    </div>
  );
};

export default SubmitProposelForm;