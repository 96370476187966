import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';

import slide1 from './image/Logo/Slide/Welcome.jpg';
import slide2 from './image/Logo/Slide/About.jpg';
import slide3 from './image/Logo/Slide/Conference.jpg';
import slide4 from './image/slide2.jpg';

const Slider = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const touchStartX = useRef(null);
  
  const slides = [
    {
      image: slide1,
      title: "Welcome to AEG",
      subtitle: "AEG Consultancy Services PVT. LTD.",
      description: "A leading provider of comprehensive research solutions, serving both academic and industrial sectors. We specialize in research publications, design and development of research setups, and supply of critical materials and laboratory equipment."
    },
    {
      image: slide2,
      title: "About Us",
      subtitle: "Excellence in Research Solutions",
      description: "AEG Consultancy Services PVT. LTD. specializes in a wide range of services, including research publications, the design and development of research setups, and the supply of critical materials and laboratory equipment."
    },
    {
      image: slide3,
      title: "Conferences",
      subtitle: "Global Research Community",
      description: "We support the global research community by organizing international conferences, fostering collaboration, and sharing cutting-edge knowledge. Partnering with organizations like DST and ICMR for scientific advancement."
    },
    {
      image: slide4,
      title: "Products & Services",
      subtitle: "End-to-End Research Solutions",
      description: "Our comprehensive range includes experimental setup development, research publication support, and advanced research facility design and construction."
    }
  ];

  useEffect(() => {
    if (!isPaused) {
      const interval = setInterval(() => {
        handleNext();
      }, 3000); // Change slide every 5 seconds
      return () => clearInterval(interval);
    }
  }, [isPaused]); // Only re-run the effect if isPaused changes

  const handleNext = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setActiveSlide((prev) => (prev + 1) % slides.length);
      setTimeout(() => setIsTransitioning(false), 500);
    }
  };

  const handlePrev = () => {
    if (!isTransitioning) {
      setIsTransitioning(true);
      setActiveSlide((prev) => (prev - 1 + slides.length) % slides.length);
      setTimeout(() => setIsTransitioning(false), 500);
    }
  };

  const handleTouchStart = (e) => {
    touchStartX.current = e.touches[0].clientX;
    setIsPaused(true);
  };

  const handleTouchEnd = (e) => {
    const touchEndX = e.changedTouches[0].clientX;
    const diff = touchStartX.current - touchEndX;

    if (Math.abs(diff) > 50) {
      if (diff > 0) {
        handleNext();
      } else {
        handlePrev();
      }
    }
    
    setTimeout(() => setIsPaused(false), 1000);
  };

  return (
    <div 
      className="relative h-screen w-full overflow-hidden bg-gray-900"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseEnter={() => setIsPaused(true)}
      onMouseLeave={() => setIsPaused(false)}
    >
      {/* Progress bar */}
      <div className="absolute top-0 left-0 w-full h-1 bg-gray-800 z-20">
        <div 
          className="h-full bg-orange-500 transition-all duration-[5000ms] ease-linear"
          style={{ 
            width: isPaused ? '0%' : '100%',
            transition: isPaused ? 'none' : 'width 5000ms linear'
          }}
        />
      </div>

      {/* Slides */}
      <div className="relative h-full">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`absolute top-0 left-0 w-full h-full transition-opacity duration-500 
              ${index === activeSlide ? 'opacity-100 z-10' : 'opacity-0 z-0'}`}
          >
            {/* Background Image */}
            <div 
              className="absolute inset-0 bg-cover bg-center transform scale-105 transition-transform duration-[5000ms]"
              style={{ 
                backgroundImage: `url(${slide.image})`,
                transform: index === activeSlide ? 'scale(1.1)' : 'scale(1)'
              }}
            />
            
            {/* Gradient Overlay */}
            <div className="absolute inset-0 bg-gradient-to-b from-black/70 via-black/50 to-black/70" />

            {/* Content */}
            <div className="relative h-full flex flex-col justify-center items-center px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto">
              <div className="space-y-6 md:space-y-8 text-center">
                <div className="space-y-2 sm:space-y-3">
                  <h2 className="text-orange-500 text-lg sm:text-xl md:text-2xl font-medium transform translate-y-4 opacity-0 animate-slideUp">
                    {slide.subtitle}
                  </h2>
                  <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-white transform translate-y-4 opacity-0 animate-slideUp">
                    {slide.title}
                  </h1>
                </div>
                
                <p className="text-gray-300 text-base sm:text-lg md:text-xl max-w-3xl transform translate-y-4 opacity-0 animate-slideUp">
                  {slide.description}
                </p>
                
                <div className="transform translate-y-4 opacity-0 animate-slideUp">
                  <Link
                    to="/ContactForm"
                    className="inline-flex items-center px-6 py-3 sm:px-8 sm:py-4 bg-orange-500 
                      text-white font-semibold rounded-full hover:bg-orange-600 
                      transition-all duration-300 transform hover:scale-105"
                  >
                    Contact Us
                    <ChevronRight className="ml-2 h-5 w-5" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Navigation Buttons */}
      <button
        onClick={handlePrev}
        className="absolute left-4 top-1/2 -translate-y-1/2 p-2 rounded-full 
          bg-black/30 hover:bg-black/50 backdrop-blur-sm transition-all duration-300
          text-white z-20 group"
      >
        <ChevronLeft className="w-6 h-6 sm:w-8 sm:h-8 transform group-hover:-translate-x-1 transition-transform" />
      </button>
      
      <button
        onClick={handleNext}
        className="absolute right-4 top-1/2 -translate-y-1/2 p-2 rounded-full 
          bg-black/30 hover:bg-black/50 backdrop-blur-sm transition-all duration-300
          text-white z-20 group"
      >
        <ChevronRight className="w-6 h-6 sm:w-8 sm:h-8 transform group-hover:translate-x-1 transition-transform" />
      </button>

      {/* Slide Indicators */}
      <div className="absolute bottom-6 left-1/2 -translate-x-1/2 flex space-x-3 z-20">
        {slides.map((_, index) => (
          <button
            key={index}
            onClick={() => setActiveSlide(index)}
            className={`h-2 rounded-full transition-all duration-300 
              ${index === activeSlide ? 'w-8 bg-orange-500' : 'w-2 bg-white/50 hover:bg-white/75'}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Slider;

// Add to your global CSS
const style = document.createElement('style');
style.textContent = `
  @keyframes slideUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .animate-slideUp {
    animation: slideUp 0.8s forwards;
  }

  .animate-slideUp:nth-child(1) { animation-delay: 0.2s; }
  .animate-slideUp:nth-child(2) { animation-delay: 0.4s; }
  .animate-slideUp:nth-child(3) { animation-delay: 0.6s; }
`;
document.head.appendChild(style);